<template>
  <div class="popup_wrap" style="width:800px;height:600px;">
    <button class="layer_close" @click.prevent="closeNmove()">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.VOSD100_M5.005') }}</h1><!-- 운임확인 -->
      <div class="content_box">
        <!-- content_box -->
        <p class="txt_desc none">
          {{ $t('msg.VOSD100.002') }} : {{ polNm }} ~ {{ $t('msg.VOSD100.003') }} : {{ podNm }}<br />
          <span v-if="legMenuChk === 'Y'">
            <span v-show="parentInfo.rteCd !== undefined">{{ $t('msg.VOSD100_M5.006') }} : {{ parentInfo.rteCd }} / {{ parentInfo.vslNm }} / {{ parentInfo.voyNo }}</span>
          </span>
        </p>
        <table class="tbl_search mt10">
          <colgroup>
            <col width="80px"><col>
          </colgroup>
          <tbody>
            <tr>
              <th class="pl0">
                {{ $t('msg.VOSD100_M5.007') }}<!--CNTR Type -->
              </th>
              <td>
                <!-- <select name="" id="" class="wid150" :disabled="containerType.length === 1" @change="changeCntnType">
                  <option v-for="vo in containerType" :key="'sel_' + vo.cd" :value="vo.cd">{{ vo.cdNm }}</option>
                </select> -->
                <select name="" id="" class="wid150" :disabled="cntrTypList.length === 1 || parentInfo.promotionChk === 'Y'" @change="changeCntnTypeV2">
                  <!-- disabled-->
                  <option v-for="vo in cntrTypList" :key="'sel_' + vo.reqRno" :value="vo.reqRno">{{ vo.asCntrTypCd }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="tbl_col mt10">
          <colgroup>
            <col width="30%">
            <col width="12%">
            <col width="10%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
            <col width="12%">
          </colgroup>
          <thead>
            <tr>
              <th>Freight Charges</th>
              <th>Currency</th>
              <th>Type</th>
              <th>Cargo</th>
              <th>20'</th>
              <th>40'</th>
              <th>HC</th>
            </tr>
          </thead>
          <tbody v-if="parentInfo.promotionChk !== 'Y'">
            <!-- 메뉴 유입시 (일반) -->
            <tr v-for="(row,idx) in surChargeList2Show" :key="idx">
              <td class="text_left" v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <span v-if="parentInfo.kmtcSpotYn === 'Y'"><!-- 프리미엄 구간이고 일반 운임인 경우 레디오타입 표시 -->
                  <input type="radio" :id="'kmtcOnN-' + idx" v-model="kmtcPremiumFrtChk" value="R" :disabled="parentInfo.detailResp2.bkgClose === 'Y'" /><!-- name 프로퍼티 추가시 더블클릭 해야 선택되는 증상이 있어 제외함 -->
                  <label :for="'kmtcOnN-' + idx" class="mr10">{{ row.frtCdNm }}</label>
                </span>
                <span v-if="parentInfo.kmtcSpotYn !== 'Y'">{{ row.frtCdNm }}</span>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate20) + parseInt(of20Rate), { isComma: true }) }}
                </div>
                <div v-else-if="row.rate20 !== '' && row.rate20 !== '0' && of20Rate !== '' && of20Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate20) + parseInt(of20Rate), { isComma: true }) }}
                </div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate40) + parseInt(of40Rate), { isComma: true }) }}
                </div>
                <div v-else-if="row.rate40 !== '' && row.rate40 !== '0' && of40Rate !== '' && of40Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate40) + parseInt(of40Rate), { isComma: true }) }}
                </div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rateHc) + parseInt(of40Rate), { isComma: true }) }}
                </div>
                <div v-else-if="row.rateHc !== '' && row.rateHc !== '0' && of40Rate !== '' && of40Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rateHc) + parseInt(of40Rate), { isComma: true }) }}
                </div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
              </td>
            </tr>
            <!-- KMTC ON인 경우 운임 노출 -->
            <tr v-show="parentInfo.kmtcSpotYn === 'Y' && cntrTypCd2 === 'GP'" v-for="(row, idx) in bfSurChargeList" :key="idx + 'Y'">
              <td class="text_left" v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <input type="radio" :id="'kmtcOnY-' + idx" v-model="kmtcPremiumFrtChk" value="P" /><!-- name 프로퍼티 추가시 더블클릭 해야 선택되는 증상이 있어 제외함 -->
                <label :for="'kmtcOnY-' + idx" class="mr10"><span></span>{{ row.frtCdNm }}</label>
                <img src="../../../assets/images/common/icon_premium_text_only.png">
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate20) + parseInt(of20Rate), { isComma: true }) }}
                </div>
                <div v-else-if="row.rate20 !== '' && row.rate20 !== '0' && of20Rate !== '' && of20Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate20) + parseInt(of20Rate), { isComma: true }) }}
                </div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate40) + parseInt(of40Rate), { isComma: true }) }}
                </div>
                <div v-else-if="row.rate40 !== '' && row.rate40 !== '0' && of40Rate !== '' && of40Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate40) + parseInt(of40Rate), { isComma: true }) }}
                </div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rateHc) + parseInt(of40Rate), { isComma: true }) }}
                </div>
                <div v-else-if="row.rateHc !== '' && row.rateHc !== '0' && of40Rate !== '' && of40Rate !== undefined">
                  {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rateHc) + parseInt(of40Rate), { isComma: true }) }}
                </div>
                <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="parentInfo.promotionChk === 'Y'">
            <tr v-for="(row,idx) in surChargeList2Show" :key="idx">
              <td class="text_left" v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.cntrTypCd === 'GP'">
                  <!-- cargo hz(01)가 아닌 경우 또는  컨테이너 타입 GP만 프로모션 계산 대상 -->
                  <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined">
                    <del>{{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate20) + parseInt(of20Rate), { isComma: true }) }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ calcPromotionDC('20', parseInt(row.rate20) + parseInt(of20Rate), row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                  </div>
                  <div v-else-if="row.rate20 !== '' && row.rate20 !== '0' && of20Rate !== '' && of20Rate !== undefined">
                    <del>{{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate20) + parseInt(of20Rate), { isComma: true }) }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ calcPromotionDC('20', parseInt(row.rate20) + parseInt(of20Rate), row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                  </div>
                  <div v-else>
                    <del>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ calcPromotionDC('20',parseInt(row.rate20), row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                  </div>
                </div>
                <div v-else>
                  <!-- GP이외 일반 가격을 보여줌 -->
                  <div v-if="row.rate20 !== '' && row.rate20 !== '0' && frtAppNo === '' && of20Rate !== '' && of20Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate20) + parseInt(of20Rate), { isComma: true }) }}
                  </div>
                  <div v-else-if="row.rate20 !== '' && row.rate20 !== '0' && of20Rate !== '' && of20Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate20) + parseInt(of20Rate), { isComma: true }) }}
                  </div>
                  <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}</div>
                </div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.cntrTypCd === 'GP'">
                  <!-- cargo hz(01)가 아닌 경우 프로모션 계산 대상 -->
                  <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                    <del>{{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate40) + parseInt(of40Rate), { isComma: true }) }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ calcPromotionDC('40', parseInt(row.rate40) + parseInt(of40Rate), row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                  </div>
                  <div v-else-if="row.rate40 !== '' && row.rate40 !== '0' && of40Rate !== '' && of40Rate !== undefined">
                    <del>{{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate40) + parseInt(of40Rate), { isComma: true }) }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ calcPromotionDC('40', parseInt(row.rate40) + parseInt(of40Rate), row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                  </div>
                  <div v-else>
                    <del>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ calcPromotionDC('40', parseInt(row.rate40), row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                  </div>
                </div>
                <div v-else>
                  <!-- GP이외 일반 가격을 보여줌 -->
                  <div v-if="row.rate40 !== '' && row.rate40 !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate40) + parseInt(of40Rate), { isComma: true }) }}
                  </div>
                  <div v-else-if="row.rate40 !== '' && row.rate40 !== '0' && of40Rate !== '' && of40Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rate40) + parseInt(of40Rate), { isComma: true }) }}
                  </div>
                  <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}</div>
                </div>
              </td>
              <td v-show="row.frtCd === 'O/F' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                <div v-if="row.cntrTypCd === 'GP'">
                  <!-- cargo hz(01)가 아닌 경우 프로모션 계산 대상 -->
                  <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                    <del>{{ $ekmtcCommon.changeNumberFormat(parseInt(row.rateHc) + parseInt(of40Rate), { isComma: true }) }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ calcPromotionDC('40', parseInt(row.rateHc) + parseInt(of40Rate), row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                  </div>
                  <div v-else-if="row.rateHc !== '' && row.rateHc !== '0' && of40Rate !== '' && of40Rate !== undefined">
                    <del>{{ $ekmtcCommon.changeNumberFormat(parseInt(row.rateHc) + parseInt(of40Rate), { isComma: true }) }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ calcPromotionDC('40', parseInt(row.rateHc) + parseInt(of40Rate), row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                  </div>
                  <div v-else>
                    <del>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</del>&nbsp;&nbsp;
                    <img src="../../../assets/images/common/icon_port_arrow.png" style="margin-top:6px">&nbsp;&nbsp;
                    {{ calcPromotionDC('40', parseInt(row.rateHc), row.frtCd, row.cntrTypCd, row.cgoTypNm) }}
                  </div>
                </div>
                <div v-else>
                  <!-- GP이외 일반 가격을 보여줌 -->
                  <div v-if="row.rateHc !== '' && row.rateHc !== '0' && frtAppNo === '' && of40Rate !== '' && of40Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rateHc) + parseInt(of40Rate), { isComma: true }) }}
                  </div>
                  <div v-else-if="row.rateHc !== '' && row.rateHc !== '0' && of40Rate !== '' && of40Rate !== undefined">
                    {{ $ekmtcCommon.changeNumberFormat(parseInt(row.rateHc) + parseInt(of40Rate), { isComma: true }) }}
                  </div>
                  <div v-else>{{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <span v-show="parentInfo.promotionChk !== 'Y' && parentInfo.kmtcSpotYn === 'Y' && cntrTypCd2 === 'GP'">KMTC ON PREMIUM 운임으로 부킹하시면 특별 선복보장 서비스를 제공합니다.</span><br v-if="parentInfo.promotionChk !== 'Y' && parentInfo.kmtcSpotYn === 'Y' && cntrTypCd2 === 'GP'">
        <span v-show="parentInfo.promotionChk !== 'Y' && parentInfo.kmtcSpotYn === 'Y' && cntrTypCd2 === 'GP'" style="font-weight: bolder;">(출항일 포함 7일 이내 PREMIUM 부킹 캔슬시 캔슬비용 20'/40' KRW 50,000/100,000 발생됩니다.)</span>
        <span v-if="parentInfo.promotionChk !== 'Y'">
          <table class="tbl_col mt10">
            <colgroup>
              <col width="30%">
              <col width="12%">
              <col width="10%">
              <col width="12%">
              <col width="12%">
              <col width="12%">
              <col width="12%">
            </colgroup>
            <thead>
              <tr>
                <th>Export Charges</th>
                <th>Currency</th>
                <th>Type</th>
                <th>Cargo</th>
                <th>20'</th>
                <th>40'</th>
                <th>HC</th>
              </tr>
            </thead>
            <!-- 일반운임 선택시 -->
            <tr v-for="(row,idx) in surChargeList2Show" :key="'a' + idx" v-show="kmtcPremiumFrtChk !== 'P'">
              <td class="text_left" v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}
              </td>
            </tr>
            <!-- KMTC ON 운임 선택시 -->
            <tr v-for="(row,idx) in bfSurChargeList" :key="'b' + idx" v-show="kmtcPremiumFrtChk === 'P'">
              <td class="text_left" v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtCd !== 'SRD' && row.frtCd !== 'AFS' && row.frtPncCd === 'P' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}
              </td>
            </tr>
          </table>
          <!-- Import Charge -->
          <table class="tbl_col mt10">
            <colgroup>
              <col width="30%">
              <col width="12%">
              <col width="10%">
              <col width="12%">
              <col width="12%">
              <col width="12%">
              <col width="12%">
            </colgroup>
            <thead>
              <tr>
                <th>Import Charges</th>
                <th>Currency</th>
                <th>Type</th>
                <th>Cargo</th>
                <th>20'</th>
                <th>40'</th>
                <th>HC</th>
              </tr>
            </thead>
            <!-- 일반운임 선택시 -->
            <tr v-for="(row,idx) in surChargeList2Show" :key="'a' + idx" v-show="kmtcPremiumFrtChk !== 'P'">
              <td class="text_left" v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}
              </td>
            </tr>
            <!-- KMTC ON 운임 선택시 -->
            <tr v-for="(row,idx) in bfSurChargeList" :key="'b' + idx" v-show="kmtcPremiumFrtChk === 'P'">
              <td class="text_left" v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.frtCdNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.curCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cntrTypCd === 'RF'?'Reefer':row.cntrTypCd }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">{{ row.cgoTypNm }}</td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate20, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rate40, { isComma: true }) }}
              </td>
              <td v-show="row.frtCd !== 'O/F' && row.frtCd !== 'DCF' && row.frtPncCd === 'C' && row.cntrTypCd !== '' && row.cntrTypCd !== 'RH' && row.cntrTypCd !== 'HC'">
                {{ $ekmtcCommon.changeNumberFormat(row.rateHc, { isComma: true }) }}
              </td>
            </tr>
          </table>
          <!-- Import Charge -->
          <!-- Notes -->
          <div v-show="surChargeNoteList.length !== 0">
            <p class="txt_desc asterisk">Notes<br /></p>
            <div v-for="(row,idx) in surChargeNoteList" :key="idx">
              <div v-show="row.frtCd === 'DCF' || row.frtCd === 'SRD' || row.frtCd === 'AFS'">
                <p class="txt_desc2">{{ idx+1 }}. Subject to {{ row.frtCdNm }} : {{ row.curCd }} {{ $ekmtcCommon.changeNumberFormat(row.blRate, { isComma: true }) }} per Bill of Lading.<br /></p>
              </div>
              <div v-if="idx === (surChargeNoteList.length-1)">
                <p class="txt_desc2">{{ idx+2 }}. Subject to SPECIAL DG HANDLING SURCHARGE : USD 600/20', USD 700/40' per container (※For only DG CLASS 2 by BUSAN T/S).<br /></p>
                <p v-show="parentInfo.porCtrCd === 'JP'" class="txt_desc2">{{ idx+3 }}. 3ヶ月更新のLSS(Low Sulphur Surcharge)は、お見積もりのタイミングによって表示されない場合がございます。
                  料率が決まり次第表示されますが、ご不明な点がございましたら弊社営業部までお問い合わせください。<br /></p>
              </div>
            </div>
          </div>
          <!-- Notes -->
          <!-- Destination Freetime -->
          <table class="tbl_col mt10">
            <colgroup>
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
            </colgroup>
            <thead>
              <tr>
                <th rowspan="2">KIND</th>
                <th colspan="2">Loading</th>
                <th colspan="2">Discharging</th>
              </tr>
              <tr v-show="cntrTypCd2 === 'GP'">
                <th style="border-left:1px solid #e1e3eb;">GP</th>
                <th>HC</th>
                <th>GP</th>
                <th>HC</th>
              </tr>
              <tr v-show="cntrTypCd2 === 'RF'">
                <th style="border-left:1px solid #e1e3eb;">RF</th>
                <th>RH</th>
                <th>RF</th>
                <th>RH</th>
              </tr>
              <tr v-show="cntrTypCd2 === 'FR'">
                <th style="border-left:1px solid #e1e3eb;">FR</th>
                <th></th>
                <th>FR</th>
                <th></th>
              </tr>
              <tr v-show="cntrTypCd2 === 'OT'">
                <th style="border-left:1px solid #e1e3eb;">OT</th>
                <th></th>
                <th>OT</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row,idx) in rateFreeDdsList" :key="idx">
                <td class="text_left">{{ row.demDetCatNm }}</td>
                <td>{{ row.ldGpFreeDds }}</td>
                <td>{{ row.ldHcFreeDds }}</td>
                <td>{{ row.disGpFreeDds }}</td>
                <td>{{ row.disHcFreeDds }}</td>
              </tr>
            </tbody>
          </table>

          <table v-show="memberDetail.userCtrCd === 'JP'" class="tbl_col mt10">
            <colgroup>
              <col width="20%">
              <col width="20%">
            </colgroup>
            <tbody>
              <tr>
                <th>LSS P/C</th>
                <td>
                  <select name="lssPc" id="" v-model="lssPc">
                    <option value="">{{ $t('msg.CSBL200.075') }}</option>
                    <option value="P">Prepaid</option>
                    <option value="C">Collect</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </span>
        <!-- Destination Freetime -->
        <span v-if="parentInfo.promotionChk !== 'Y'">
          <p class="txt_desc asterisk ">{{ $t('msg.CSBL250.047') }} : {{ startDate }} ~ {{ endDate }}<br /></p><!-- 운임 유효기간 -->
        </span>
        <span v-if="auth.userCtrCd === 'KR' && checkCharge !== 'Y' && (cntrTypCd2 === 'OT' || cntrTypCd2 === 'FR')">
          <span class="txt_desc asterisk" style="top:4px;"><a ref="#" @click="clickOpen('oog-notice')" style="color:blue;cursor:pointer; font-weight: bold;">[상세내용]</a> 버튼으로 특수화물 선적 및 운임관련 공지를 확인하세요.<br></span>
          <span class="txt_desc asterisk" style="top:6px;">공지를 확인하셨다면 우측의 체크박스를 체크하세요.</span>
          <input
            v-model="oogChk"
            type="checkbox"
            id="agree"
            name="agree"
          >
          <label for="agree" style="top:5px;">{{ $t('msg.ADMN030G020.042') }}</label><!--  -->
        </span>
        <p class="txt_desc asterisk">
          {{ $t('msg.VOSD100_M5.008') }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<!-- SC No. 생성을 위해서는 운임확정이 필요합니다. --> <!-- 문구와 운임확정 버튼 간격을 위해 &nbsp; 추가함 -->
          <span class="ml5" v-show="checkCharge === 'N' && parentInfo.detailResp2.bkgClose !== 'Y'">
            <!--<a class="button sm" href="#" @click.prevent="fnAccept">{{ $t('msg.VOSD100_M5.009') }}</a> 운임확정 -->
            <a class="button blue sm" href="#" @click.prevent="acceptQuote">{{ $t('msg.VOSD100_M5.009') }}</a><!-- 운임확정 -->
          </span>
          <!-- 운임확정 예 클릭한 경우 -->
          <span class="ml5" v-show="checkCharge === 'Y'">
            <a class="button select sm" href="#">{{ $t('msg.VOSD100_M5.009') }}<!-- 운임확정 --></a>
            <span class="color_blue font_medium">(SC No. : {{ frtAppNo }})</span>
          </span>
        </p>
        <span v-if="parentInfo.promotionChk === 'Y'">
          <p class="txt_desc asterisk" v-html="$t('msg.CSBL250.052')"></p><!-- 확정된 프로모션 운임은 <b>해당 선명항차에만</b> 적용됩니다. -->
        </span>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <span v-show="parentInfo.raTsParam !== undefined && cntrTypCd2 !== 'OT' && cntrTypCd2 !== 'FR'"><a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" href="#" @click.prevent="goBooking">Booking</a></span>
        <a v-show="checkCharge === 'Y' && parentInfo.detailResp2.kmtcSpotUserYn === 'Y' && parentInfo.kmtcPremiumNegoYn === 'Y' && parentInfo.detailResp2.bkgClose !== 'Y'" class="button green lg mr5" href="#" @click.prevent="goReqQtPop('K')">{{ $t('msg.MAIN010G030.038') }} NOW로 운임 협의하기<!-- KMTC ON --></a>
        <span v-show="cntrTypCd2 === 'OT' || cntrTypCd2 === 'FR'"><a :class="urlOrNot ? 'button blue lg action' : 'button blue lg'" href="#" @click.prevent="clickOpen('oog-cargo-chk-pop')">{{ $t('msg.CSBK100.510') }}</a><!-- 카고 체크 --></span>
        <span v-show="checkCharge === 'Y' && parentInfo.promotionChk !== 'Y' && parentInfo.detailResp2.kmtcSpotUserYn !== 'Y' && parentInfo.kmtcSpotUserYn !== 'Y'">&nbsp;<a class="button blue lg" href="#" @click.prevent="goNegoPop">{{ $t('msg.VOSD100_M5.010') }}<!-- 네고요청 --></a></span>
        <span v-if="parentInfo.promotionChk !== 'Y' && parentInfo.detailResp2.kmtcSpotUserYn !== 'Y'">&nbsp;<a class="button blue lg" @click.prevent="fnFrtConfirm()">{{ $t('msg.VOSD100_M5.026') }}</a></span><!-- 운임문의 -->
        &nbsp;<span><a class="button gray lg" href="#" @click.prevent="closeNmove()">{{ $t('msg.VOSD100.042') }}<!-- 닫기 --></a></span>
        &nbsp;<span><a class="button lg" href="javascript:none(0)" @click.prevent="screenPrint"><span class="btn_icon print"></span>인쇄</a></span>
      </div>
      <div class="flex_box mt10" v-show="checkCharge === 'Y' && parentInfo.detailResp2.kmtcSpotUserYn === 'Y' && parentInfo.kmtcPremiumNegoYn === 'Y' && parentInfo.detailResp2.bkgClose !== 'Y'">
        <span style="color: #075bb9; font-weight: 500; font-size: 15px; margin-left: 31%; margin-top:-10px;">KMTC ON NOW를 클릭해보세요. e-KMTC에서 운임을 즉시 협의하실 수 있습니다.</span>
      </div>
      <div id="pdf_area"></div>
    </div>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        @close="closePopup"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import schedules from '@/api/rest/schedule/schedule'
import myScheduleApi from '@/api/rest/schedule/mySchedule'
import { rootComputed } from '@/store/helpers'
import countryCode from '@/plugins/country.json'

export default {
  name: 'FreSurchargePop2',
  components: {
    OogDgInquiryPop: () => import('@/pages/trans/popup/OogDgInquiryPop'),
    'oog-notice': () => import('@/pages/schedule/popup/OogNotice'),
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'oog-cargo-chk-pop': () => import('@/pages/trans/popup/OogCargoChkPop')
  },
  computed: {
    ...rootComputed
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: '',
          polNm: '',
          podNm: '',
          etd: '',
          frtAppNo: '',
          vslCd: '',
          vslNm: '',
          voyNo: '',
          rteCd: '',
          eiCatCd: '',
          frtResult: '',
          reqRno: '',
          bkgClose: '',
          raTsParam: '',
          negoYN: '',
          etaBookingMsg: '',
          promotionChk: 'N',
          scenarioCd: '',
          promoNo: '',
          applyFr: '',
          applyTo: '',
          ofCntrSize01: '',
          ofCntrType01: '',
          ofDcRate01: 0,
          ofDcType01: '',
          ofCntrSize02: '',
          ofCntrType02: '',
          ofDcRate02: 0,
          ofDcType02: '',
          dtCntrSize01: '',
          dtCntrType01: '',
          dtDcRate01: 0,
          dtDcType01: '',
          dtCntrSize02: '',
          dtCntrType02: '',
          dtDcRate02: 0,
          dtDcType02: '',
          kmtcSpotYn: 'N',
          detailResp2: {},
          kmtcPremiumNegoYn: 'N'
        }
      }
    }
  },
  data: function () {
    return {
      startDate: '',
      endDate: '',
      strDt: '',
      endDt: '',
      polNm: '',
      podNm: '',
      surChargeList: [],
      surChargeNoteList: [],
      surChargeGPList: [],
      surChargeRFList: [],
      surChargeOOGList: [],
      frtAppNo: '',
      of20Rate: '',
      of40Rate: '',
      spread20: 0,
      spread40: 0,
      schgReqRno: '',
      cntrTypCd2: '',
      checkCharge: 'N',
      containerType: [],
      surChargeListDry: [],
      surChargeListRee: [],
      surChargeListEtc: [],
      surChargeList2Show: [],
      rateFreeDdsList: [],
      cntrTypList: [],
      vslReqRno: '',
      vslSeq: '',
      acceptYn: false,
      legMenuChk: 'N',
      urlOrNot: false, // JamSin DSM
      oogChk: false,
      frtAppNoGP: '',
      frtAppNoRF: '',
      frtAppNoOT: '',
      frtAppNoFR: '',
      customComponent: null,
      lssPc: '',
      kmtcPremiumFrtChk: false,
      bfSurChargeList: [],
      apv20Ft: '',
      apv40Ft: ''
    }
  },
  async created () {
    console.log('this.auth', this.auth)
    console.log('>>>>>> [freSurcharge2] this.parentInfo <<<<<<')
    //console.log(this.parentInfo)
    this.urlOrNot = this.$ekmtcCommon.isNotEmpty(this.parentInfo.urlOrNot) ? this.parentInfo.urlOrNot : false // JamSin DSM 트래킹을 위해 스케줄에서 urlOrNot 넘김

    const params = {
      scenarioCd: this.parentInfo.scenarioCd,
      promoNo: this.parentInfo.promoNo
    }

    if (this.parentInfo.promotionChk === 'Y') {
      await myScheduleApi.getPromotionDcAmt(params).then(res => {
        this.parentInfo.applyFr = res.data[0].applyFr
        this.parentInfo.applyTo = res.data[0].applyTo
        this.parentInfo.ofCntrSize01 = res.data[0].ofCntrSize01
        this.parentInfo.ofCntrType01 = res.data[0].ofCntrType01
        this.parentInfo.ofDcRate01 = res.data[0].ofDcRate01
        this.parentInfo.ofDcType01 = res.data[0].ofDcType01
        this.parentInfo.ofCntrSize02 = res.data[0].ofCntrSize02
        this.parentInfo.ofCntrType02 = res.data[0].ofCntrType02
        this.parentInfo.ofDcRate02 = res.data[0].ofDcRate02
        this.parentInfo.ofDcType02 = res.data[0].ofDcType02
        this.parentInfo.dtCntrSize01 = res.data[0].dtCntrSize01
        this.parentInfo.dtCntrType01 = res.data[0].dtCntrType01
        this.parentInfo.dtDcRate01 = res.data[0].dtDcRate01
        this.parentInfo.dtDcType01 = res.data[0].dtDcType01
        this.parentInfo.dtCntrSize02 = res.data[0].dtCntrSize02
        this.parentInfo.dtCntrType02 = res.data[0].dtCntrType02
        this.parentInfo.dtDcRate02 = res.data[0].dtDcRate02
        this.parentInfo.dtDcType02 = res.data[0].dtDcType02
      }).catch(e => {
        console.log(e)
      })
    }

    // 메뉴 경로로 현재 스케줄 메뉴 여부 확인
    if (this.$route.path === '/schedule/leg') {
      this.legMenuChk = 'Y'
    }

    // 메인에서 운임문의 할 경우 운임확인 팝업창의 KMTC ON 버튼 노출
    if (this.$route.path === '/main') {
      this.parentInfo.detailResp2 = Object.assign({}, this.parentInfo.detailResp2, { kmtcSpotUserYn: this.parentInfo.kmtcSpotUserYn })
      this.parentInfo.detailResp2 = Object.assign({}, this.parentInfo.detailResp2, { kmtcSpotLineYn: 'N' })
    }

    // 운임 메뉴에서 운임확인 할 경우 운임확인 팝업창의 KMTC ON 버튼 강제 비노출 처리
    // 노출이 필요한 경우 kmtcSpotUserYn 항목을 메인과 똑같이 맞추면 버튼 노출됨
    if (this.$route.path === '/shipping-cost/inquiry' || this.$route.path === '/shipping-cost/my' || this.$route.path === '/kmtc-premium') {
      this.parentInfo.detailResp2 = Object.assign({}, this.parentInfo.detailResp2, { kmtcSpotUserYn: 'N' })
      this.parentInfo.detailResp2 = Object.assign({}, this.parentInfo.detailResp2, { kmtcSpotLineYn: 'N' })
    }

    if (this.parentInfo.detailResp2.bkgClose === 'Y' && this.parentInfo.detailResp2.kmtcSpotClosYn === 'N') {
      this.kmtcPremiumFrtChk = 'P'
    }

    this.getCntrList()
    // this.getFreSurcharge()
  },
  methods: {
    getCntrList () {
      vslScheduleInfo.popFreRateNoCntr(this.parentInfo).then((rtnData) => {
        // console.log('>>>>>> [freSurcharge] popFreRateNoCntr <<<<<<')
        // console.log(rtnData)
        this.cntrTypList = rtnData.data.cntrTypList
        if (this.cntrTypList === undefined || this.cntrTypList.length === 0) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
        }
        this.parentInfo.reqRno = this.cntrTypList[0].reqRno
        this.cntrTypCd2 = this.cntrTypList[0].cntrTypCd
        this.getFreSurcharge()
      })
    },
    async getCntrTypeMessage () {
      let message = this.$t('msg.CSCT060G040.010') + '<br><br>'

      message += '<div class="d_flex">'
      message += '  <div class="inp_space30">Type : </div>'
      message += '  <div class="inp_space70">'
      message += '  <select id="popCntrTypCd">'
      message += '  <option value="">' + this.$t('msg.CSBL200.075') + '</option>'
      message += '  <option value="GP">Dry</option>'
      message += '  <option value="RF">Reefer</option>'
      message += '  <option value="FR">Flat Rack</option>'
      message += '  <option value="OT">Open Top</option>'
      message += '  </select>'
      message += '  </div>'
      message += '</div>'

      let rtnVal = ''
      const isOk = await this.$ekmtcCommon.asyncAlertMessage({
        message: message,
        useConfirmBtn: true,
        onConfirmSelf: (resolve, $Simplert) => {
          const popCntrTypCd = document.querySelector('#popCntrTypCd')

          if (this.$ekmtcCommon.isEmpty(popCntrTypCd.value)) {
            this.$ekmtcCommon.showErrorTooltip(popCntrTypCd, this.$t('msg.CSBK100.147'))
          } else {
            rtnVal = popCntrTypCd.value
            resolve(true)
            $Simplert.close()
          }
        }
      })

      return {
        isOk: isOk,
        rtnVal: rtnVal
      }
    },
    async fnFrtConfirm () {
      const parentInfo = this.parentInfo
      const params = {
        porCtrCd: parentInfo.porCtrCd,
        porPlcCd: parentInfo.porPlcCd,
        dlyCtrCd: parentInfo.dlyCtrCd,
        dlyPlcCd: parentInfo.dlyPlcCd,
        etd: parentInfo.etd,
        eiCatCd: parentInfo.eiCatCd
      }

      const cntrMsgInfo = await this.getCntrTypeMessage()

      if (cntrMsgInfo.isOk) {
        params.cntrTypCd = cntrMsgInfo.rtnVal

        await schedules.fnPopFreReq(params).then((response) => {
          const result = response.data.insertRateMngResult
          let msg = ''
          if (result !== undefined && result === 1) {
            //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
            msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.
          } else if (result !== undefined && result === 2) { // 등록된 운임이 있음
            msg = this.$t('msg.FARE010T010.024')
          } else if (result !== undefined && result === 3) { // 요청중인 내역이 있는경우 메시지만 동일하게 출력
            //msg = this.$t('msg.FARE010T010.022') // 문의하신 구간에 대한 운임등록 요청이 접수되었습니다. 잠시 후에 다시 확인하시기 바랍니다.
            msg = this.$t('msg.FARE010T010.025') // 현재 요청하신 기간 및 구간에 등록된 운임이 없습니다.
          } else {
            msg = this.$t('msg.FARE010T010.023') // 처리 중 오류가 발생하였습니다. 시스템 관리자에게 문의해 주세요.
          }
          this.$ekmtcCommon.alertDefault(msg)
        })
      }
    },
    getFreSurcharge () {
      this.frtAppNo = this.parentInfo.frtAppNo
      // 출발, 도착지명
      var polnm = this.parentInfo.polNm.split(',')
      var podnm = this.parentInfo.podNm.split(',')
      if (polnm.length > 1) {
        this.polNm = polnm[1] + ' / ' + polnm[0]
      } else {
        this.polNm = this.parentInfo.polNm
      }
      if (podnm.length > 1) {
        this.podNm = podnm[1] + ' / ' + podnm[0]
      } else {
        this.podNm = this.parentInfo.podNm
      }
      // 운임 Data
      let dry = 'N'
      let reefee = 'N'
      this.surChargeGPList = []
      this.surChargeRFList = []
      this.surChargeOOGList = []
      this.surChargeList2Show = []
      this.surChargeNoteList = []
      this.parentInfo.cntrTypCd = this.cntrTypCd2
      this.parentInfo.frtAppNo = ''
      this.parentInfo.frtResult = ''
      vslScheduleInfo.getPopFreSurcharge(this.parentInfo).then((rtnData) => {
        // console.log('######### [freSurcharge] getPopFreSurcharge #########')
        // console.log(rtnData)
        this.surChargeList = rtnData.data.surChargeList
        // bfSurChargeList는 프로모션 운임인 경우 할인 전 금액 조회용으로 사용하고, KMTC ON의 경우 KMTC ON 운임 조회용으로 사용한다.
        this.bfSurChargeList = rtnData.data.bfSurChargeList
        this.rateFreeDdsList = rtnData.data.rateFreeDdsList
        this.of20Rate = rtnData.data.of20Rate
        this.of40Rate = rtnData.data.of40Rate

        for (const item of this.surChargeList) {
          // KMTC ON 버튼용 세팅
          if (item.frtCd === 'O/F' && item.cntrTypCd === 'GP' && item.cgoTypCd !== '01') {
            this.apv20Ft = item.rate20
            this.apv40Ft = item.rate40
          }
          // 기존 세팅
          if (item.frtCd === 'DCF' || item.frtCd === 'SRD' || item.frtCd === 'AFS') {
            this.surChargeNoteList.push(item)
          }
          if (this.cntrTypCd2 === 'GP') {
            //if (item.cntrTypCd === 'GP' || item.cntrTypCd === 'HC') {
              this.surChargeGPList.push(item)
            //}
          } else if (this.cntrTypCd2 === 'RF') {
           // if (item.cntrTypCd === 'RF' || item.cntrTypCd === 'RH') {
              this.surChargeRFList.push(item)
           // }
          } else if (this.cntrTypCd2 === 'OT' || this.cntrTypCd2 === 'FR') {
           // if (item.cntrTypCd === 'OT' || item.cntrTypCd === 'FR') {
              this.surChargeOOGList.push(item)
          //  }
          }
        }
        if (this.cntrTypCd2 === 'GP') {
          this.surChargeList2Show = this.surChargeGPList
        } else if (this.cntrTypCd2 === 'RF') {
          this.surChargeList2Show = this.surChargeRFList
        } else {
          this.surChargeList2Show = this.surChargeOOGList
        }
        // 운임 유효기간
        this.startDate = rtnData.data.strDt.substring(0, 4) + '-' + rtnData.data.strDt.substring(4, 6) + '-' + rtnData.data.strDt.substring(6, 8)
        this.endDate = rtnData.data.endDt.substring(0, 4) + '-' + rtnData.data.endDt.substring(4, 6) + '-' + rtnData.data.endDt.substring(6, 8)
        this.strDt = rtnData.data.strDt
        this.endDt = rtnData.data.endDt
        this.schgReqRno = rtnData.data.schgReqRno
        if (this.surChargeList === undefined || this.surChargeList.length === 0) {
          this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.ONEX010T010.073') }) // 검색 결과가 없습니다.
        }
        this.vslReqRno = rtnData.data.vslReqRno
        this.vslSeq = rtnData.data.vslSeq
      })
    },
    changeCntnTypeV2 (e) {
      const val = e.target.value

      // 컨테이너 타입 GP 아닌 경우 운임 선택 일반으로 강제 세팅
      if (val !== 'GP') {
        this.kmtcPremiumFrtChk = 'R'
      }

      // 프로모션 유입시 reqRno 값이 바뀌는 경우가 있어 프로모션 유입인 경우 변경 못하도록 처리함
      this.checkCharge = 'N'
      this.oogChk = false
      if (this.parentInfo.promotionChk !== 'Y') {
        this.parentInfo.reqRno = val
      }
      for (const item of this.cntrTypList) {
        if (item.reqRno === val) {
          this.cntrTypCd2 = item.cntrTypCd
        }
      }
      if (this.cntrTypCd2 === 'GP' && this.$ekmtcCommon.isNotEmpty(this.frtAppNoGP)) {
          this.checkCharge = 'Y'
          this.parentInfo.frtAppNo = this.frtAppNoGP
        } else if (this.cntrTypCd2 === 'RF' && this.$ekmtcCommon.isNotEmpty(this.frtAppNoRF)) {
          this.checkCharge = 'Y'
          this.parentInfo.frtAppNo = this.frtAppNoRF
        } else if (this.cntrTypCd2 === 'OT' && this.$ekmtcCommon.isNotEmpty(this.frtAppNoOT)) {
          this.checkCharge = 'Y'
          this.parentInfo.frtAppNo = this.frtAppNoOT
        } else if (this.cntrTypCd2 === 'FR' && this.$ekmtcCommon.isNotEmpty(this.frtAppNoFR)) {
          this.checkCharge = 'Y'
          this.parentInfo.frtAppNo = this.frtAppNoFR
        }
      this.getFreSurcharge()
    },
    changeCntnType (e) {
      const val = e.target.value
      this.surChargeList2Show = []
      if (val === 'D') {
        this.surChargeList2Show = this.surChargeListDry
      } else if (val === 'R') {
        this.surChargeList2Show = this.surChargeListRee
      } else {
        this.surChargeList2Show = this.surChargeListEtc
      }
    },
    fnAccept () {
      if (this.surChargeList.length !== 0) {
        let obj = {
          // onClose: this.closeConfirm,
          onConfirm: this.acceptQuote,
          customCloseBtnText: this.$t('msg.VOSD100_M5.004'),
          customCloseBtnClass: 'button blue lg',
          customConfirmBtnText: this.$t('msg.VOSD100_M5.003'),
          customConfirmBtnClass: 'button blue lg',
          useConfirmBtn: true,
          message: this.$t('msg.VOSD100_M5.001'),
          type: 'info'
        }
        this.$ekmtcCommon.alert(obj)
      }
    },
    closeConfirm () {
      // console.log('######### [freSurcharge] closeConfirm #########')
    },
    acceptQuote () {
      window.app.$Simplert.close() //컨텀창 안닫혀서 추가
/*
      let startDt = ''
      let overDt = ''

      // 프로모션인 경우 운임의 유효일자는 프로모션 기간과 동일하게 세팅함
      if (this.parentInfo.promotionChk === 'Y') {
        startDt = this.parentInfo.applyFr
        overDt = this.parentInfo.applyTo
      } else {
        startDt = this.strDt
        overDt = this.endDt
      }
*/
      if (this.parentInfo.detailResp2.bkgClose === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('마감된 구간의 운임은 확정할 수 없습니다.'))
        return
      }

      if (this.auth.userCtrCd === 'KR' && (this.cntrTypCd2 === 'OT' || this.cntrTypCd2 === 'FR')) {
        if (!this.oogChk) {
          this.$ekmtcCommon.alertDefault('특수화물 운임확정을 위해서는 공지사항 확인표시가 필수입니다')
          return
        }
      }
      const params = {
        porCtrCd: this.parentInfo.porCtrCd,
        porPlcCd: this.parentInfo.porPlcCd,
        dlyCtrCd: this.parentInfo.dlyCtrCd,
        dlyPlcCd: this.parentInfo.dlyPlcCd,
        etd: this.parentInfo.etd,
//        strDt: startDt,
//        endDt: overDt,
        strDt: this.strDt,
        endDt: this.endDt,
        reqRno: this.parentInfo.reqRno,
        rteCd: this.parentInfo.rteCd,
        vslCd: this.parentInfo.vslCd,
        voyNo: this.parentInfo.voyNo,
        schgReqRno: this.schgReqRno,
        of20Rate: this.of20Rate,
        of40Rate: this.of40Rate,
        cntrTypeCd: this.cntrTypCd2,
        eiCatCd: this.parentInfo.eiCatCd,
        promotionChk: this.parentInfo.promotionChk,
        scenarioCd: this.parentInfo.scenarioCd,
        promoNo: this.parentInfo.promoNo,
        vslReqRno: this.vslReqRno,
        vslSeq: this.vslSeq,
        spread20: this.spread20,
        spread40: this.spread40,
        hotDealYn: this.cntrTypCd2 === 'GP' ? 'Y' : 'N',
        lssPc: this.lssPc
      }
      vslScheduleInfo.insertAcceptQuote(params).then((rtnData) => {
        // console.log('######### [freSurcharge] insertAcceptQuote #########')
        // console.log(rtnData)
        this.surChargeList = rtnData.data.surChargeList
        this.frtAppNo = rtnData.data.frtAppNo
        this.checkCharge = 'Y'
        this.parentInfo.frtResult = rtnData.data.frtResult
        this.parentInfo.frtAppNo = rtnData.data.frtAppNo
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.SCHD010P071.001') }) // 문의하신 구간의 운임 SC No. 가 생성되었습니다.
        // // 운임 유효기간
        // this.startDate = rtnData.data.strDt.substring(0, 4) + '-' + rtnData.data.strDt.substring(4, 6) + '-' + rtnData.data.strDt.substring(6, 8)
        // this.endDate = rtnData.data.endDt.substring(0, 4) + '-' + rtnData.data.endDt.substring(4, 6) + '-' + rtnData.data.endDt.substring(6, 8)
        this.acceptYn = true
        if (this.cntrTypCd2 === 'GP') {
          this.frtAppNoGP = rtnData.data.frtAppNo
        } else if (this.cntrTypCd2 === 'RF') {
          this.frtAppNoRF = rtnData.data.frtAppNo
        } else if (this.cntrTypCd2 === 'OT') {
          this.frtAppNoOT = rtnData.data.frtAppNo
        } else if (this.cntrTypCd2 === 'FR') {
          this.frtAppNoFR = rtnData.data.frtAppNo
        }
       //this.getFreSurcharge()
      })
    },
    goNegoPop () {
      this.parentInfo.negoYN = 'Y'
      let param = { ...this.parentInfo }
      param.acceptYn = this.acceptYn
      this.$emit('close', param)
    },
    async goBooking () {
      if (this.bfSurChargeList !== undefined && !this.kmtcPremiumFrtChk) {
        this.$ekmtcCommon.alertDefault(this.$t('운임을 선택해주세요.'))
        return
      }

      if (this.parentInfo.detailResp2.bkgClose === 'Y' && this.kmtcPremiumFrtChk !== 'P') {
        this.$ekmtcCommon.alertDefault(this.$t('마감된 구간은 부킹할 수 없습니다.'))
        return
      }

      if (!this.$ekmtcCommon.isEmpty(this.parentInfo.etaBookingMsg)) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.parentInfo.etaBookingMsg }) // 해당 국가 ( 한국 ) 수출화물에 대해서만 부킹이 가능합니다.
        return
      }

      if (this.parentInfo.hongkongTsYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.parentInfo.hongkongTsMsg) // 중국 춘절 기간동안 홍콩 T/S가 중단
        return
      }

      // KMTC ON 프리미엄 운임 선택시 운임 생성
      if (this.kmtcPremiumFrtChk === 'P') {
        const params = {
          porCtrCd: this.parentInfo.porCtrCd, // [11]출발지 국가코드
          porPlcCd: this.parentInfo.porPlcCd, // [9]출발지 포트코드
          dlyCtrCd: this.parentInfo.dlyCtrCd, // [12]도착지 국가코드
          dlyPlcCd: this.parentInfo.dlyPlcCd, // [10]도착지 포트코드
          etd: this.parentInfo.etd, // [5]입항일
          rteCd: this.parentInfo.rteCd,
          vslCd: this.parentInfo.vslCd, // [0]선명코드
          voyNo: this.parentInfo.voyNo, // [1]항차
          eiCatCd: this.parentInfo.eiCatCd,
          of20Rate: this.bfSurChargeList.filter(vo => vo.frtCd === 'O/F' && vo.cntrTypCd === 'GP')[0].rate20,
          of40Rate: this.bfSurChargeList.filter(vo => vo.frtCd === 'O/F' && vo.cntrTypCd === 'GP')[0].rate40,
          strDt: this.startDate.replace(/-/gi, ''),
          endDt: this.endDate.replace(/-/gi, '')
        }

        await vslScheduleInfo.kmtcSpotAcceptQuote(params).then((rtnData) => {
          this.parentInfo.detailResp2.frtAppNo = rtnData.data.frtAppNo
          this.parentInfo.detailResp2.refFrtAppNo = ''
        })

        if (this.parentInfo.detailResp2.frtAppNo === '' && this.parentInfo.detailResp2.frtAppNo === undefined) {
          this.$ekmtcCommon.alertDefault(this.$t('운임확정 오류발생'))
          return
        }
      }

      if (this.kmtcPremiumFrtChk !== 'R' || this.checkCharge === 'Y') { // KMTC ON 운임 선택 또는 운임 확정시
        if (this.kmtcPremiumFrtChk !== 'P') { // KMTC ON 운임이 아닌 일반운임 선택시
          this.parentInfo.detailResp2.frtAppNo = this.frtAppNo || ''
          this.parentInfo.kmtcSpotYn = 'N'
          this.parentInfo.detailResp2.kmtcSpotLineYn = 'N'
        } else if (this.kmtcPremiumFrtChk === 'P') { // 프리미엄 선택시 KMTC ON 세팅
          this.parentInfo.kmtcSpotYn = 'Y'
          this.parentInfo.detailResp2.kmtcSpotLineYn = 'Y'
        }
        let param = { ...this.parentInfo }
        param.acceptYn = this.acceptYn
        this.$emit('close', param)
        // emit의 booking 파라미터 실행시 ScheduleLeg.vue 파일의 bookingBooking2() 실행되도록 세팅됨
        this.$emit('booking', param.detailResp2, this.kmtcPremiumFrtChk)
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.VOSD100_M5.063'))
      }
    },
    booking () {
      console.log('######### [freSurcharge2] booking #########')
    },
    calcPromotionDC (cntrType, originAmt, frtCd, cntrTypCd, cgoTypNm) {
      let calcAmt = 0
      let calcDcRate = 0

      // originAmt 숫자가 아닌 경우
      if (originAmt <= 0 || originAmt === 'NaN') {
        return this.$ekmtcCommon.changeNumberFormat(originAmt, { isComma: true })
      }

      if (frtCd === 'O/F' && cntrTypCd === 'GP') {
        // GP and Cargo HZ 가 아니면서 직접할인 이면, 차액계산 저장하여 Cargo HZ 할인시 사용함
        if (cgoTypNm === undefined && cntrType === '20') {
          this.spread20 = originAmt - this.parentInfo.dtDcRate01
        } else if (cgoTypNm === undefined && cntrType === '40') {
          this.spread40 = originAmt - this.parentInfo.dtDcRate02
        }

        // 프로모션의 offset과 직접운임간 우선순위가 결정되지 않아 우선 offset에 우선순위를 두고 계산함.
        // 금액 계산시 %, 정액 할인은 원단위 올림
        // offset 금액 계산 1
        if (cntrType === this.parentInfo.ofCntrSize01) {
          if (this.parentInfo.ofDcType01 === '$') {
            calcAmt = originAmt - this.parentInfo.ofDcRate01
          } else { // % 계산
            calcDcRate = this.parentInfo.ofDcRate01 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }

        // offset 금액 계산 2
        if (cntrType === this.parentInfo.ofCntrSize02) {
          if (this.parentInfo.ofDcType02 === '$') {
            calcAmt = originAmt - this.parentInfo.ofDcRate02
          } else { // % 계산
            calcDcRate = this.parentInfo.ofDcRate02 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }

        // 직접운임 금액 계산 1
        if (cntrType === this.parentInfo.dtCntrSize01) {
          if (this.parentInfo.dtDcType01 === '$') {
            if (cgoTypNm === 'HZ') {
              calcAmt = originAmt - this.spread20
            } else {
              calcAmt = this.parentInfo.dtDcRate01
            }
          } else { // % 계산
            calcDcRate = this.parentInfo.dtDcRate01 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }

        // 직접운임 금액 계산 2
        if (cntrType === this.parentInfo.dtCntrSize02) {
          if (this.parentInfo.dtDcType02 === '$') {
            if (cgoTypNm === 'HZ') {
              calcAmt = originAmt - this.spread40
            } else {
              calcAmt = this.parentInfo.dtDcRate02
            }
          } else { // % 계산
            calcDcRate = this.parentInfo.dtDcRate02 / 100
            calcAmt = originAmt - Math.floor(calcDcRate * originAmt)
          }
          if (calcAmt <= 0) {
            calcAmt = 1
          }
          return this.$ekmtcCommon.changeNumberFormat(calcAmt, { isComma: true })
        }
      }
      return this.$ekmtcCommon.changeNumberFormat(originAmt, { isComma: true })
    },
    closeNmove () {
      this.$emit('close', { acceptYn: this.acceptYn })
      if (this.parentInfo.promotionChk === 'Y') {
        this.$router.push('/main')
      }
    },
    screenPrint () {
      const ele = document.querySelector('.content_box')
      this.$ekmtcCommon.elemAddClass(ele, 'screen_shot')

      const options = {
        cssSelector: '.content_box',
        page: 'FreeSurcharge'
      }

      this.$ekmtcCommon.screenToPrint(options).then(res => {
        if (res === undefined || res === null) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.244'))
        }
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      }).catch(() => {
        this.$ekmtcCommon.elemRemoveClass(ele, 'screen_shot')
      })
    },
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.popup_wrap > .popup_dim')
    },
    closePopup () {
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.popup_wrap > .popup_dim')
    },
    clickOpen (kind) {
      this.openPopup(kind)
    },
    goReqQtPop (negoYN) {
      if (negoYN === 'K') {
        if (this.parentInfo.detailResp2.kmtcSpotLineYn === 'Y' && !this.kmtcPremiumFrtChk) {
          this.$ekmtcCommon.alertDefault('운임을 선택해주세요.')
          return
        }

        if (this.kmtcPremiumFrtChk === 'P') {
          this.$ekmtcCommon.alertDefault('KMTC ON PREMIUM 운임은 협의 대상이 아닙니다.')
          return
        }

        if (this.dcYn === 'Y') {
          this.$ekmtcCommon.alertDefault('이미 KMTC ON NOW로 협의 완료된 운임입니다.')
          return
        }

        if (this.raOrRm !== 'RA' && this.checkCharge !== 'Y') {
          this.$ekmtcCommon.alertDefault('운임 확정 후 KMTC ON NOW 페이지로 이동 가능합니다.')
          return
        }

        const params = {
          frtAppNo: this.parentInfo.frtAppNo,
          porCtrNm: countryCode[this.parentInfo.porCtrCd].CountryNameEN,
          porPlcNm: this.parentInfo.polNm.split(',')[0],
          dlyCtrNm: countryCode[this.parentInfo.dlyCtrCd].CountryNameEN,
          dlyPlcNm: this.parentInfo.podNm,
          expDate: this.startDate + ' ~ ' + this.endDate,
          apv20Ft: this.apv20Ft,
          apv40Ft: this.apv40Ft,
          porPlcCd: this.parentInfo.porPlcCd,
          porCtrCd: this.parentInfo.porCtrCd,
          dlyPlcCd: this.parentInfo.dlyPlcCd,
          dlyCtrCd: this.parentInfo.dlyCtrCd
        }

        this.$emit('close', this.parentInfo)
        this.$router.push({ name: 'kmtc-premium', query: params }).catch(() => {})
      } else {
        this.parentInfo.negoYN = negoYN
        this.$emit('close', this.parentInfo)
      }
    }
  }
}
</script>
